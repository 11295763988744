<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <label>Para iniciar su seleccion de cursos, haga click en el boton de trainings que aparece derecha de su pantalla.</label>
        </div>
        <div class="col-8">
          <img src="/media/help_image/sec_1.png"
               style="width: inherit"
          />
        </div>
      </div>
      <hr>
      <div class="row mt-7">
        <div class="col-4">
          <label>Se visualizará una lista de los trainings. Haciendo clic en el ícono
            <v-icon disabled small>
            mdi-loupe
          </v-icon>, verá la lista de cursos del training seleccionado.
          Para seleccionar los cursos, haga click en los cuadritos que están disponibles a la izquierda de cada curso.</label>
        </div>
        <div class="col-8">
          <img src="/media/help_image/sec_02.png"
               style="width: inherit"
          />
        </div>
      </div>
      <hr>
      <div class="row mt-7">
        <div class="col-4">
          <label>Puede  obtener mayor información del curso haciendo clic en el ícono <v-icon disabled small>
            mdi-information
          </v-icon></label>
        </div>
        <div class="col-8">
          <img src="/media/help_image/sec_03.png"
               style="width: inherit"
          />
        </div>
      </div>
      <hr>
      <div class="row mt-7">
        <div class="col-4">
          <label>Una vez seguro de su selección, haga clic en el botón de "Finalizar".</label>
        </div>
        <div class="col-8">
          <img src="/media/help_image/sec_04.png"
               style="width: inherit"
          />
        </div>
      </div>
    </div>
    </div>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Help",
  data() {
    return {
      isLoading: false,
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bienvenido(a) a Trainning" }]);
  },
  methods: {},
};
</script>
